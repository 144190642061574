import styled from "styled-components";
//import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import IS_LOGGED_IN from "../../api/queries/IS_LOGGED_IN";

import banner_logged_in from "../../images/banner_logged_in.png";
import banner_logged_out from "../../images/banner_logged_out.png";



const CarouselContainerOut = styled.div`
  text-align: left;
  width: 100%;
  height: 20vw;
  background: rgba(11, 31, 60, 0.5);
  background-image: url(${banner_logged_out});
  border-radius: 0.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

`;
const CarouselContainerIn = styled.div`
  text-align: left;
  width: 100%;
  height: 20vw;
  background: rgba(11, 31, 60, 0.5);
  background-image: url(${banner_logged_in});
  border-radius: 0.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

`;

const BannerContainer = ({ expanded }) => {
  const { data: loginData } = useQuery(IS_LOGGED_IN);
  if (loginData.isLoggedIn) {
    return (
      <>
        <CarouselContainerIn  style={{}}>
        </CarouselContainerIn>
      </>
    );
    } else {
    return (
      <>
        <CarouselContainerOut  style={{}}>
        </CarouselContainerOut>
      </>
    );
    
    }
};

export default BannerContainer;
